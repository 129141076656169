import React from 'react';
import { ActionIcon, Box, Grid, Text, Tooltip } from "@mantine/core";
import LoadIcon from "../Icon/LoadIcon";
import {v4 as uuid} from 'uuid';

export function NavItemGroup(props) {
    const onDrag = (event, nodeType, data, id) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.setData('data', JSON.stringify(data));
        event.dataTransfer.setData('id', JSON.stringify(id));
        event.dataTransfer.effectAllowed = 'move';
    };

    const onDbClick = (event, nodeType, data, id) => {
        const newNode = {
            id: uuid(),
            type: nodeType,
            data: {...data}
        };
        props.handleDbClick(newNode)
    }

    return (
        <Grid.Col span={6} style={{ padding: '0px 0px 0px 8px' }}>
            {
                props.link?.description ? (
                    <Tooltip multiline label={props.link?.description || undefined}>
                        <Box
                            className={props.styleCss.link}
                            sx={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}
                            onDragStart={(event) => onDrag(event, props.link.data.typeNode, props.link.data)}
                            onDoubleClick={(event) => onDbClick(event, props.link.data.typeNode, props.link.data)}
                            draggable
                        >
                            <ActionIcon color={props.link.data.color ?? "blue"} variant="transparent">
                                <LoadIcon icon={props.link.data.icon} size={16} />
                            </ActionIcon>
                            <Text ml="md">{props.link.data.label}</Text>
                        </Box>
                    </Tooltip>
                ) : (
                    <Box
                        className={props.styleCss.link}
                        sx={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}
                        onDragStart={(event) => onDrag(event, props.link.data.typeNode, props.link.data)}
                        onDoubleClick={(event) => onDbClick(event, props.link.data.typeNode, props.link.data)}
                        draggable
                    >
                        <ActionIcon color={props.link.data.color ?? "blue"} variant="transparent">
                            <LoadIcon icon={props.link.data.icon} size={16} />
                        </ActionIcon>
                        <Text ml="md">{props.link.data.label}</Text>
                    </Box>
                )
            }
        </Grid.Col>
    )
}