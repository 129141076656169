import React, { useState, useEffect } from 'react';
import {Box, Flex, NumberInput, Radio, Select} from '@mantine/core';
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";
import VariableTextarea from "../../../GlobalComponent/VariableTextarea";
import VariableNumberInput from "../../../GlobalComponent/VariableNumberInput";

const Scroll = (props) => {
    const [dataState, setDataState] = useState({
        selectorType: props?.nodeData?.data?.options.selectorType ?? 'selector',
        elementSelect: props?.nodeData?.data?.options.elementSelect ?? "",
        fixedElement: props?.nodeData?.data?.options.fixedElement ?? 1,
        deltaX: props?.nodeData?.data?.options.deltaX ?? 0,
        deltaY: props?.nodeData?.data?.options.deltaY ?? 0,
        randomStepFrom: props?.nodeData?.data?.options.randomStepFrom ?? 400,
        randomStepTo: props?.nodeData?.data?.options.randomStepTo ?? 1000,
        randomDelayWheelFrom: props?.nodeData?.data?.options.randomDelayWheelFrom ?? 500,
        randomDelayWheelTo: props?.nodeData?.data?.options.randomDelayWheelTo ?? 1000,
        containingElement: props?.nodeData?.data?.options.containingElement ?? '',
    });

    const handleData = (type) => (value) => {
        if (typeof value === 'string' || typeof value === 'number') {
            setDataState({...dataState, [type]: value});
        } else {
            setDataState({...dataState, [type]: value.target.value});
        }
    }

    const handleSelect = (type, value) => {
        if (typeof value === 'string' || typeof value === 'number') {
            setDataState({...dataState, [type]: value});
        } else if (typeof value === 'boolean') {
            setDataState({...dataState, [type]: value});
        } else {
            setDataState({...dataState, [type]: value.target.value});
        }
    }

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    return (
        <Box>
            <Radio.Group
                value={dataState.selectorType}
                label="Choose type"
                onChange={handleData('selectorType')}
                name="element-exists"
            >
                <Radio value="selector" label="Selector" />
                <Radio value="coordinates" label="Coordinates" />
            </Radio.Group>
            {dataState.selectorType === 'selector' &&
                <>
                    <VariableSelectWrite
                        style={{marginBottom:"10px"}}
                        dataState={dataState.elementSelect} label="Select element" placeholder="Please enter the element, such as #email input"
                        handleData={handleData('elementSelect')}
                        handleSelect={(e) => {
                            handleSelect('elementSelect', e.newValue)
                        }}
                    />
                    <Flex
                        mih={50}
                        gap="md"
                        justify="flex-start"
                        align="flex-start"
                        direction="row"
                        wrap="wrap"
                    >
                        <VariableNumberInput
                            style={{width:"200px"}}
                            label="Element order"
                            dataState={dataState.fixedElement}
                            handleData={handleData('fixedElement')}
                        />
                    </Flex>
                </>
            }
            {dataState.selectorType === 'coordinates' &&
                <>
                    <VariableSelectWrite
                        label="Enter X coordinate"
                        dataState={dataState.deltaX}
                        handleData={handleData('deltaX')}
                        handleSelect={(e) => {
                            handleSelect('deltaX', e.newValue)
                        }}
                    />
                    <VariableSelectWrite
                        label="Enter Y coordinate"
                        dataState={dataState.deltaY}
                        handleData={handleData('deltaY')}
                        handleSelect={(e) => {
                            handleSelect('deltaY', e.newValue)
                        }}
                    />
                </>
            }
            <VariableSelectWrite
                style={{marginBottom:"10px"}}
                dataState={dataState.containingElement}
                label="Containing element (Optional)"
                placeholder="Perform scrolling only within this element"
                handleData={handleData('containingElement')}
                handleSelect={(e) => {
                    handleSelect('containingElement', e.newValue)
                }}
            />
            <label className="mantine-InputWrapper-label mantine-Textarea-label mantine-ittua2" style={{ marginTop: 16 }}>Speed:</label>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <VariableSelectWrite
                    label="Random scroll step from (px)"
                    dataState={dataState.randomStepFrom}
                    handleData={handleData('randomStepFrom')}
                    handleSelect={(e) => {
                        handleSelect('randomStepFrom', e.newValue)
                    }}
                    style={{ marginTop: 0, flex: 1 }}
                />
                <VariableSelectWrite
                    label="Random scroll step to (px)"
                    dataState={dataState.randomStepTo}
                    style={{ marginTop: 0, flex: 1, marginLeft: '12px' }}
                    handleData={handleData('randomStepTo')}
                    handleSelect={(e) => {
                        handleSelect('randomStepTo', e.newValue)
                    }}
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <VariableSelectWrite
                    label="Delay scroll step from (ms)"
                    dataState={dataState.randomDelayWheelFrom}
                    handleData={handleData('randomDelayWheelFrom')}
                    handleSelect={(e) => {
                        handleSelect('randomDelayWheelFrom', e.newValue)
                    }}
                    style={{ flex: 1 }}
                />
                <VariableSelectWrite
                    label="Delay scroll step to (ms)"
                    dataState={dataState.randomDelayWheelTo}
                    style={{ flex: 1, marginLeft: '12px' }}
                    handleData={handleData('randomDelayWheelTo')}
                    handleSelect={(e) => {
                        handleSelect('randomDelayWheelTo', e.newValue)
                    }}
                />
            </div>
        </Box>
    );
}

export default Scroll;
