import { useContext, useEffect, useMemo, useState } from "react";
import { AppSettingsContext } from "../../../../core/context/AppSettingsContext";
import { Accordion, Button, Checkbox, Select, Switch, Text } from "@mantine/core";
import VariableSelectCreate from "../../../GlobalComponent/VariableSelectCreate";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";
import VariableNumberInput from "../../../GlobalComponent/VariableNumberInput";
import { replaceVariablesWithValue } from "../../../../constants/replaceVariablesWithValue.const";
import { DataFlowContext } from "../../../../core/context/DataFlowContext";

const LIST_MAIL_SERVICE = [
  {
    label: "Gmail",
    value: "imap.gmail.com"
  },
  {
    label: "Outlook/Hotmail",
    value: "outlook.office365.com"
  },
  {
    label: "Yahoo",
    value: "imap.mail.yahoo.com"
  },
  {
    label: "Custom",
    value: "custom"
  }
]

const defaultState = {
  mailService: 'imap.gmail.com',
  imapHost: '',
  imapPort: '',
  tlsSecure: true,
  email: '',
  password: '',
  mailBox: 'INBOX',
  unseenMailOnly: true,
  markMailAsRead: false,
  getLatestMail: true,
  mailContains: '',
  contentRegex: '',
  timeout: 30000,
  outputVariable: "",
  contentMailContains: '',
}

function ReadMail(props) {
  const { settings } = useContext(AppSettingsContext)
  const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? defaultState)
  const [loading, setLoading] = useState(false)
  const variablesContext = useContext(DataFlowContext)
  const onCheckBox = (key) => (e) => {
    setDataState({ ...dataState, [key]: e.target.checked })
  }

  const onChangeValue = (key, target) => e => {
    if (e?.type === 'change') {
      setDataState((state) => ({ ...state, [key]: target ? e.target[target] : e.target.value }))
    } else if (e?.type === 'click') {
      const newValue = e.newValue || ""
      setDataState((state) => ({ ...state, [key]: newValue }))
    } else {
      setDataState((state) => ({ ...state, [key]: e }))
    }
  }

  useEffect(() => {
    props.handleSetDataBaseModal({ ...dataState })
  }, [props, dataState])

  return (
    <>
      <Select
        label="Email service"
        placeholder="Pick value"
        data={LIST_MAIL_SERVICE}
        defaultValue="imap.gmail.com"
        value={dataState.mailService}
        onChange={value => setDataState((s) => ({ ...s, mailService: value }))}
      />
      {
        dataState.mailService === 'custom' && (
          <>
            <VariableSelectWrite
              placeholder="Your email"
              label="IMAP host"
              dataState={dataState.imapHost}
              handleData={onChangeValue('imapHost')}
              handleSelect={onChangeValue('imapHost')}
            />
            <VariableSelectWrite
              placeholder="Your Password"
              label="IMAP port"
              dataState={dataState.imapPort}
              handleData={onChangeValue('imapPort')}
              handleSelect={onChangeValue('imapPort')}
            />
            <Switch
              className="mt-3"
              label="TLS secure"
              checked={dataState.tlsSecure}
              onChange={event => setDataState((s) => ({ ...s, tlsSecure: event.target.checked }))}
            />
          </>
        )
      }
      <VariableSelectWrite
        placeholder="Your email"
        label="Email"
        dataState={dataState.email}
        handleData={onChangeValue('email')}
        handleSelect={onChangeValue('email')}
      />
      <VariableSelectWrite
        placeholder="Your Password"
        label="Password"
        dataState={dataState.password}
        handleData={onChangeValue('password')}
        handleSelect={onChangeValue('password')}
      />
      <VariableSelectWrite
        placeholder="INBOX"
        label="Mailbox"
        dataState={dataState.mailBox}
        handleData={onChangeValue('mailBox')}
        handleSelect={onChangeValue('mailBox')}
      />
      <div style={{ marginTop: '20px' }}>
        <Checkbox
          checked={dataState.unseenMailOnly}
          label="Unseen mail only"
          onChange={onCheckBox('unseenMailOnly')}
        />
        <Checkbox
          checked={dataState.markMailAsRead}
          label="Mark mail as read"
          onChange={onCheckBox('markMailAsRead')}
        />
        <Checkbox
          checked={dataState.getLatestMail}
          label="Get latest mail"
          onChange={onCheckBox('getLatestMail')}
        />
      </div>
      {
        !dataState.getLatestMail && (
          <VariableSelectWrite
            placeholder="Email"
            label="Sender email contains"
            dataState={dataState.mailContains}
            handleData={onChangeValue('mailContains')}
            handleSelect={onChangeValue('mailContains')}
          />
        )
      }
      <VariableSelectWrite
        placeholder="Regex"
        label="Content mail contains"
        dataState={dataState.contentMailContains}
        handleData={onChangeValue('contentMailContains')}
        handleSelect={onChangeValue('contentMailContains')}
      />
      <VariableNumberInput
        label="Read mail timeout (milliseconds)"
        dataState={dataState.timeout}
        handleData={onChangeValue("timeout")}
        isMillisecond={true}
      />
      <VariableSelectCreate
        label="Output variable"
        placeholder="Select variable"
        value={dataState.outputVariable}
        handleChange={onChangeValue("outputVariable")}
      />
    </>
  );
}


export default ReadMail;