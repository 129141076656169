import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import { NotFound } from './components/Error/NotFound';
import { AppSettingsProvider } from './core/context/AppSettingsContext';

const router = createBrowserRouter([
    {
        path: "flow",
        element: <App />,
    },
    {
        path: "/",
        element: <App />,
        errorElement: <NotFound />,
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
        <AppSettingsProvider>
            <RouterProvider router={router} />
        </AppSettingsProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
