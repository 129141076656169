export function compareAppVersions(version1, version2) {
  // Chia chuỗi phiên bản thành các mảng
  const parts1 = version1.split('.');
  const parts2 = version2.split('.');

  // So sánh từng phần
  for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
    // Nếu một phiên bản ngắn hơn, coi như phần còn lại là 0
    const part1 = parseInt(parts1[i]) || 0;
    const part2 = parseInt(parts2[i]) || 0;

    if (part1 < part2) {
      return -1;
    } else if (part1 > part2) {
      return 1;
    }
  }
  // Nếu tất cả các phần đều bằng nhau, hai phiên bản bằng nhau
  return 0;
}
