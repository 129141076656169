import React, { useContext, useState } from "react";
import { ActionIcon, Popover } from "@mantine/core";
import { IconCode } from "@tabler/icons";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";

import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-okaidia.css"; //Example style, you can use another
import style from "./codeEditor.module.css";
import { DataFlowContext } from "../../core/context/DataFlowContext";

export default function CodeEditor({ value, onChange, onValueChange, header, footer, placeholder }) {
  const dataSelect = useContext(DataFlowContext);
  const [openSelectVariable, setOpenSelectVariable] = useState(false);

  const onClickVariable = (variable) => {
    onChange(value+'${'+variable+'}')
  }

  return (
    <Popover opened={openSelectVariable}>
      <div className={style.wrap}>
        <Popover.Target>
          <div className={style.container}>
            <p style={{ fontSize: 16 }}>{header}</p>
            <Editor
              value={value}
              onChange={onChange}
              onClick={() => setOpenSelectVariable(false)}
              onValueChange={onValueChange}
              highlight={(code) => highlight(code, languages.js)}
              padding={10}
              className={style.editor}
              placeholder={placeholder}
            />
            <p style={{ fontSize: 16 }}>{footer}</p>

            <ActionIcon
              onClick={() => setOpenSelectVariable(!openSelectVariable)}
              variant="filled"
              color="yellow"
              className={style.btn_variable}
            >
              <IconCode size={16} />
            </ActionIcon>
          </div>
        </Popover.Target>
      </div>
      <Popover.Dropdown style={{ maxHeight: "36vh", overflow: "auto" }}>
        <ul style={{width: "534px"}}>
          {dataSelect.nodeValue.variables.map((item) => (
            <li onClick={() => onClickVariable(item.value)} key={item.value} className={style.select_item}>
              {item.value}
            </li>
          ))}
        </ul>
      </Popover.Dropdown>
    </Popover>
  );
}
