import { ActionIcon, Text, ThemeIcon } from "@mantine/core";
import { IconEdit, IconPlayerPlay, IconTrash } from "@tabler/icons";
import style from "./style.module.css"
import { Handle } from "reactflow";
import LoadIcon from "../../Icon/LoadIcon";

function NodeElementExists({ id, data, isConnectable }) {

  const handleStartNode = (e) => {
    e.stopPropagation();
    data.onStartOneNode(id)
  }

  const handleRemoveNode = (e) => {
    e.stopPropagation();
    data.onRemoveNode(id);
  }

  const handleOpenNode = (e) => {
    e.stopPropagation();
    data.onUpdateNode(id)
  }

  return (
    <div className={`${style.node_normal} node-normal ${data.isActive && 'active'}`} style={{
      height: `${(data?.options?.elementSelects?.length - 1) * 16 + 46}px`
    }}>
      <div className='action-node'>
        <div className='left-node'>
          <ActionIcon onClick={handleStartNode} color="green" size="sm" radius="xl">
            <IconPlayerPlay size={14} />
          </ActionIcon>
          <ActionIcon onClick={handleOpenNode} color="blue" size="sm" radius="xl">
            <IconEdit size={14} />
          </ActionIcon>
        </div>
        <ActionIcon onClick={handleRemoveNode} color="red" size="sm" radius="xl">
          <IconTrash size={14} />
        </ActionIcon>
      </div>
      <div className='node-normal-body'>
        <ThemeIcon className="node-icon">
          <LoadIcon icon={data.icon} size={16} />
        </ThemeIcon>
        <Text size="0.8rem">{data.label}</Text>
      </div>
      <Handle
        id={`in-${id}`}
        type="target"
        position="left"
        style={{ background: "var(--input)", left: "-6px" }}
        isConnectable={isConnectable}
      />
      {data?.options?.elementSelects.map((_, index) => (
        <Handle
          id={`success-elementSelect-${index}-${id}`}
          type="source"
          key={index}
          position="right"
          style={{ background: "var(--output)", top: `${index * 16 + 15}px` }}
          isConnectable={isConnectable}
        />
      ))}
      <Handle
        id={`error-${id}`}
        type="source"
        position="right"
        style={{ background: "var(--output-bottom)", top: `${data?.options?.elementSelects?.length * 16 + 15}px` }}
        isConnectable={isConnectable}
      />
    </div>
  );
}

export default NodeElementExists;