import { Card, Checkbox, Group, Image, Text, Tooltip, useMantineTheme } from "@mantine/core";
import { IconSettingsAutomation } from "@tabler/icons";
import classes from "./style.module.css"

function ItemNode({ isSelected, node, toggleSelect }) {
  const theme = useMantineTheme()
  
  return (
    <Card
      onClick={() => toggleSelect(node?.data.options.id)}
      p="xs"
      style={{
        boxShadow: "0px 0px 4.43px 0px #00000026",
        cursor: "pointer",
        overflow: "unset"
      }}
    >
      <Group position="apart">
        <Group position="left" spacing={0}>
          <IconSettingsAutomation />
          <Tooltip label={node?.data.label}>
            <Text fw={500} className={classes.title} style={{
              color: isSelected ? " #2263B3" : theme.colorScheme === "light" ? theme.colors.dark[9] : theme.colors.gray[2]
            }}>{node?.data.label}</Text>
          </Tooltip>
        </Group>
        <Checkbox
          checked={isSelected}
          onChange={() => toggleSelect(node?.data.options.id)}
        />
      </Group>
      <div style={{
        marginTop: ".5rem"
      }}>
        <Image height={100} src={node?.data.options?.image ? node?.data.options.image :'/images/config.png'} radius="sm" alt="Tesla Model S" />
      </div>
      <Tooltip multiline={true} label={node?.description} w={300} withArrow>
        <Text size="sm" mt={4} className={classes.desc}>{node?.description}</Text>
      </Tooltip>
    </Card>
  );
}

export default ItemNode;