import { Group, Box, Collapse, ThemeIcon, Text, UnstyledButton, createStyles, Grid } from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons';
import { NavItemGroup } from './NavItemGroup';

export const useStyles = createStyles((theme) => ({
    control: {
        fontWeight: 500,
        display: 'block',
        width: '100%',
        padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2],
        // backgroundColor: theme.colors.dark[7],
        fontSize: theme.fontSizes.sm,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
            color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        },
    },

    link: {
        fontWeight: 500,
        // display: 'block',
        textDecoration: 'none',
        // padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
        padding: '8px 8px',
        // paddingLeft: 31,
        // marginLeft: 30,
        fontSize: theme.fontSizes.xl,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
        // borderLeft: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
        //     }`,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
            color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        },
    },

    chevron: {
        transition: 'transform 200ms ease',
    },
}));

export function LinksGroup({ icon: Icon, label, initiallyOpened, links, index, handleOpenNode, handleDbClick }) {
    const { classes, theme } = useStyles();
    const hasLinks = Array.isArray(links);
    const ChevronIcon = theme.dir === 'ltr' ? IconChevronRight : IconChevronLeft;

    const items = (hasLinks ? links : []).map((link, index) => (
        <NavItemGroup styleCss={classes} link={link} key={index} index={index} handleDbClick={handleDbClick}/>
    ));

    return (
        <>
            <UnstyledButton style={{padding: '6px 16px'}} onClick={() => handleOpenNode(index)} className={classes.control}>
                <Group position="apart" spacing={0}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ThemeIcon color="green" variant="light" size="md">
                            <Icon size={20} />
                        </ThemeIcon>
                        <Text ml="md">{label}</Text>
                    </Box>
                    {hasLinks && (
                        <ChevronIcon
                            className={classes.chevron}
                            size={14}
                            stroke={1.5}
                            style={{
                                transform: initiallyOpened ? `rotate(${theme.dir === 'rtl' ? -90 : 90}deg)` : 'none',
                            }}
                        />
                    )}
                </Group>
            </UnstyledButton>
            {hasLinks ? <Collapse in={initiallyOpened}>
                <Grid style={{ margin: 0}}>{items}</Grid>
            </Collapse> : null}
        </>
    );
}