import { Checkbox, Select } from "@mantine/core";
import { useEffect, useState } from "react";
import VariableNumberInput from "../../../GlobalComponent/VariableNumberInput";
import VariableSelectCreate from "../../../GlobalComponent/VariableSelectCreate";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";

const LIST_AUTH = [
    {
        label: "Email/Password",
        value: "LOGIN"
    },
    {
        label: "Refresh Token",
        value: "REFRESH_TOKEN"
    }
]

const defaultState = {
    authType: 'LOGIN',
    refreshToken: '',
    email: '',
    password: '',
    mailBox: 'INBOX',
    markMailAsRead: false,
    getLatestMail: true,
    subjectFilter: '',
    contentRegex: '',
    timeout: 30000,
    outputVariable: "",
    contentMailContains: '',
    refreshTokenOutput: ""
}

function ReadMailOauth2(props) {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? defaultState)
    const onCheckBox = (key) => (e) => {
        setDataState({ ...dataState, [key]: e.target.checked })
    }

    const onChangeValue = (key, target) => e => {
        if (e?.type === 'change') {
            setDataState((state) => ({ ...state, [key]: target ? e.target[target] : e.target.value }))
        } else if (e?.type === 'click') {
            const newValue = e.newValue || ""
            setDataState((state) => ({ ...state, [key]: newValue }))
        } else {
            setDataState((state) => ({ ...state, [key]: e }))
        }
    }

    useEffect(() => {
        props.handleSetDataBaseModal({ ...dataState })
        console.log(dataState)
    }, [props, dataState])

    return (
        <>
            <Select
                label="Authentication type"
                placeholder="Pick value"
                data={LIST_AUTH}
                defaultValue={LIST_AUTH[0].value}
                value={dataState.authType}
                onChange={value => setDataState((s) => ({ ...s, authType: value }))}
            />
            {
                dataState.authType === 'REFRESH_TOKEN' && (
                    <>
                        <VariableSelectWrite
                            placeholder="Type Refresh Token"
                            label="Refresh Token"
                            dataState={dataState.refreshToken}
                            handleData={onChangeValue('refreshToken')}
                            handleSelect={onChangeValue('refreshToken')}
                        />
                    </>
                )
            }

            {
                dataState.authType === 'LOGIN' && (
                    <>
                        <VariableSelectWrite
                            placeholder="Your email"
                            label="Email"
                            dataState={dataState.email}
                            handleData={onChangeValue('email')}
                            handleSelect={onChangeValue('email')}
                        />
                        <VariableSelectWrite
                            placeholder="Your Password"
                            label="Password"
                            dataState={dataState.password}
                            handleData={onChangeValue('password')}
                            handleSelect={onChangeValue('password')}
                        />
                    </>
                )
            }

            <VariableSelectWrite
                placeholder="INBOX"
                label="Mailbox"
                dataState={dataState.mailBox}
                handleData={onChangeValue('mailBox')}
                handleSelect={onChangeValue('mailBox')}
            />

            <div style={{ marginTop: '20px' }}>
                <Checkbox
                    checked={dataState.markMailAsRead}
                    label="Mark mail as read"
                    onChange={onCheckBox('markMailAsRead')}
                />
                <Checkbox
                    checked={dataState.getLatestMail}
                    label="Get latest mail"
                    onChange={onCheckBox('getLatestMail')}
                />
            </div>
            {
                !dataState.getLatestMail && (
                    <VariableSelectWrite
                        placeholder="Subject"
                        label="Subject email contains"
                        dataState={dataState.subjectFilter}
                        handleData={onChangeValue('subjectFilter')}
                        handleSelect={onChangeValue('subjectFilter')}
                    />
                )
            }
            <VariableSelectWrite
                placeholder="Regex"
                label="Content mail contains"
                dataState={dataState.contentMailContains}
                handleData={onChangeValue('contentMailContains')}
                handleSelect={onChangeValue('contentMailContains')}
            />
            <VariableNumberInput
                label="Read mail timeout (milliseconds)"
                dataState={dataState.timeout}
                handleData={onChangeValue("timeout")}
                isMillisecond={true}
            />
            <VariableSelectCreate
                label="Output variable"
                placeholder="Select variable"
                value={dataState.outputVariable}
                handleChange={onChangeValue("outputVariable")}
            />
            <VariableSelectCreate
                label="Refresh Token variable"
                placeholder="Select variable"
                value={dataState.refreshTokenOutput}
                handleChange={onChangeValue("refreshTokenOutput")}
            />
        </>
    );
}


export default ReadMailOauth2;