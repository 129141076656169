import React, { useState, useEffect, useContext } from 'react';
import {Box, Flex, NumberInput, Radio, Select, Switch,} from '@mantine/core';
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";
import VariableNumberInput from "../../../GlobalComponent/VariableNumberInput";
import { versions } from '../../../../core/Navbar/automationVersion';
import { AppSettingsContext } from '../../../../core/context/AppSettingsContext';

const typeInitial = [
    { value: 'click', label: 'Click' },
    { value: 'doubleClick', label: 'Double click' },
]
const newOptions_v1 = [
    { value: 'clickDown', label: 'Click down' },
    { value: 'clickUp', label: 'Click up' },
]

const Click = (props) => {
    const { settings } = useContext(AppSettingsContext)
    const [dataState, setDataState] = useState({
        button: props?.nodeData?.data?.options?.button ?? 'left',
        buttonClick: props?.nodeData?.data?.options?.buttonClick ?? 'click',
        selectorType: props?.nodeData?.data?.options?.selectorType ?? 'selector',
        elementSelect: props?.nodeData?.data?.options?.elementSelect ?? '',
        elementOrder: props?.nodeData?.data?.options?.elementOrder ?? 'fixed',
        fixedElement: props?.nodeData?.data?.options?.fixedElement ?? 1,
        randomElementFrom: props?.nodeData?.data?.options?.randomElementFrom ?? 1,
        randomElementTo: props?.nodeData?.data?.options?.randomElementTo ?? 50,
        x: props?.nodeData?.data?.options?.x ?? 0,
        y: props?.nodeData?.data?.options?.y ?? 0,
        mouse_human: props?.nodeData?.data?.options?.mouse_human ?? false,
    })

    const [optionsClick] = useState([
        ...typeInitial, 
        ...(settings.autoVersion >= versions[1].autoVersion ? newOptions_v1 : []),
    ])

    const handleSelector = (type, value) => {
        if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
            setDataState({...dataState, [type]: value});
        } else {
            setDataState({...dataState, [type]: value.target.value});
        }
    }

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    return (
        <>
            <Box component="form" mx="auto">
                <Flex
                    mih={50}
                    gap="md"
                    justify="flex-start"
                    align="flex-end"
                    direction="row"
                    wrap="wrap"
                >
                    <Select
                        value={dataState.button}
                        label="Click type"
                        placeholder="Pick one"
                        style={{width:"150px"}}
                        data={[
                            { value: 'left', label: 'Left' },
                            { value: 'right', label: 'Right' },
                            { value: 'middle', label: 'Middle' },
                        ]}
                        onChange={(e) => {
                            handleSelector('button', e)
                        }}
                    />
                    <Select
                        value={dataState.buttonClick}
                        label="Button click type"
                        placeholder="Pick one"
                        style={{width:"150px"}}
                        data={optionsClick}
                        onChange={(e) => {
                            handleSelector('buttonClick', e)
                        }}
                    />
                    <Switch
                        label="Mouse move human"
                        checked={!!dataState.mouse_human}
                        onClick={() => handleSelector('mouse_human', !dataState.mouse_human)}
                    />
                </Flex>

                <Radio.Group
                    spacing="sm"
                    mt="md"
                    name="target-type"
                    label="Choose one"
                    value={dataState.selectorType}
                    onChange={(e) => {
                        handleSelector('selectorType', e);
                    }}
                >
                    <Radio value="selector" label="Selector" />
                    <Radio value="coordinates" label="Coordinates" />
                </Radio.Group>

                {dataState.selectorType === 'selector' &&
                    <>
                        <VariableSelectWrite
                            style={{marginBottom:"10px"}}
                            dataState={dataState.elementSelect} label="Select element" placeholder="Please enter the element, such as #email input"
                            setDataState={handleSelector}
                            handleData={(e) => {
                                handleSelector('elementSelect', e)
                            }}
                            handleSelect={(e) => {
                                handleSelector('elementSelect', e.newValue)
                            }}
                        />
                        <Flex
                            mih={50}
                            gap="md"
                            justify="flex-start"
                            align="flex-start"
                            direction="row"
                            wrap="wrap"
                        >
                            <Select
                                value={dataState.elementOrder}
                                label="Element order"
                                placeholder="Pick one"
                                style={{width:"150px"}}
                                data={[
                                    { value: 'fixed', label: 'Fixed Value' },
                                    { value: 'random', label: 'Random Value' },
                                ]}
                                onChange={(e) => {
                                    handleSelector('elementOrder', e)
                                }}
                            />
                            {dataState.elementOrder === "fixed" &&
                                <VariableNumberInput
                                    style={{width:"200px"}}
                                    label=" "
                                    dataState={dataState.fixedElement}
                                    handleData={(e) => {
                                        handleSelector('fixedElement', e)
                                    }}
                                />
                            }
                            {dataState.elementOrder === "random" &&
                                <>
                                    <NumberInput
                                        style={{width:"150px"}}
                                        value={dataState.randomElementFrom}
                                        label="from"
                                        min={1}
                                        onChange={(e) => {
                                            handleSelector('randomElementFrom', e)
                                        }}
                                    />
                                    <NumberInput
                                        style={{width:"150px"}}
                                        value={dataState.randomElementTo}
                                        label="to"
                                        min={1}
                                        onChange={(e) => {
                                            handleSelector('randomElementTo', e)
                                        }}
                                    />
                                </>
                            }
                        </Flex>
                    </>
                }

                {dataState.selectorType === 'coordinates' &&
                    <div>
                        <VariableSelectWrite
                            label="Enter X coordinate"
                            dataState={dataState.x}
                            handleData={(e) => {
                                handleSelector('x', e);
                            }}
                            handleSelect={(e) => {
                                handleSelector('x', e.newValue)
                            }}
                        />
                        <VariableSelectWrite
                            label="Enter Y coordinate"
                            dataState={dataState.y}
                            handleData={(e) => {
                                handleSelector('y', e);
                            }}
                            handleSelect={(e) => {
                                handleSelector('y', e.newValue)
                            }}
                        />
                    </div>
                }
            </Box>
        </>
    );
}

export default Click;
