import React, { memo, useContext } from 'react';
import { Handle } from 'reactflow';
import {IconBan, IconTrash, IconX} from '@tabler/icons';
import {ActionIcon, Text} from '@mantine/core';
import './Stop.css'
import { AppSettingsContext } from '../../../core/context/AppSettingsContext';

const Stop = ({id, data, isConnectable }) => {
    const { nodeActive } = useContext(AppSettingsContext)
    const isActive = nodeActive?.id === id

    const handleRemoveNode = (e) => {
        e.stopPropagation();
        data.onRemoveNode(id);
    }
    return (
        <>
            <div className={`node-stop ${isActive && 'active'}`}>
                <ActionIcon onClick={handleRemoveNode} className="remove-node" color="red" size="sm" radius="xl">
                    <IconTrash size={14} />
                </ActionIcon>
                <IconBan size={16} />
                {data.fontSize ?
                    <Text size={data.fontSize}>{data.label}</Text>
                    :
                    <Text>{data.label === "Stop Listen Request"?"SLR":data.label}</Text>
                }
                <Handle
                    id={`in-${id}`}
                    type="target"
                    position="left"
                    isConnectable={isConnectable}
                    style={{ background: "var(--input)", right: "-6px" }}
                />
            </div>

        </>
    );
}

export default memo(Stop);