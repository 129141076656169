import React, {useContext, useEffect, useState} from "react";
import {Radio, Textarea} from "@mantine/core";
import {DataFlowContext} from "../../../../core/context/DataFlowContext";

const AddComment = (props) => {
    const dataFlowContext = useContext(DataFlowContext);

    const handleChange = (type, value) => {
        if (typeof value === 'string') {
            dataFlowContext.setComment({...dataFlowContext.comment, [props.nodeData.id]: value});
        } else {
            dataFlowContext.setComment({...dataFlowContext.comment, [props.nodeData.id]: value.target.value});
        }
    }

    useEffect(() => {
        if (props?.nodeData?.data?.options) {
            dataFlowContext.setComment({...dataFlowContext.comment, [props.nodeData.data.options.id]: props.nodeData.data.options.value})
        }
    }, []);

    useEffect(() => {
        props.handleSetDataBaseModal({
            id : props.nodeData.id,
            value : dataFlowContext.comment[props.nodeData.id]
        })
    }, [props, dataFlowContext.comment])

    return (
        <>
            <Textarea
                label="Enter your comment"
                autosize
                value={dataFlowContext.comment[props.nodeData.id]}
                onChange={(e) => {
                    handleChange("comment", e)
                }}
            />
        </>
    );
}

export default AddComment;