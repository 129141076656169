import React, {useEffect, useState} from 'react';
import {TextInput, ActionIcon, Grid, Flex} from '@mantine/core';
import { IconCode } from '@tabler/icons';
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";
import VariableNumberInput from "../../../GlobalComponent/VariableNumberInput";

const ReloadPage = (props) => {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        reload: true,
         timeout: 30000
    });

    const handleChangeUrl = (type, value) => {
        if (typeof value === 'string') {
            setDataState({...dataState, [type]: value})
            props.handleSetDataBaseModal({ type: value })
        } else {
            setDataState({...dataState, [type]: value.target.value})
        }
    }

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    return (
        <>
            <VariableNumberInput
                label="Timeout waiting (milliseconds)"
                dataState={dataState.timeout}
                handleData={(e) => {
                    handleChangeUrl('timeout',e)
                }}
                isMillisecond={true}
            />
        </>
    );
}

export default ReloadPage;
