import React, { useState } from "react";
import { MultiSelect, Select } from "@mantine/core";
import { pressKey } from "./dataPressKey";

const PressKey = (props) => {
    const [options, setOptions] = useState({
        pressKey: props?.nodeData?.data?.options?.pressKey ?? [],
        type: props?.nodeData?.data?.options?.type ?? 'PRESS',
    });

    const onSelectChange = (key) => (value) => {
        const newOptions = { ...options, [key]: value }
        setOptions(newOptions);
        props.handleSetDataBaseModal(newOptions);
    }

    return (
        <>
            <MultiSelect
                data={pressKey}
                placeholder="Keys"
                label="Select key(s)"
                searchable
                nothingFound="Nothing found"
                value={options.pressKey}
                onChange={onSelectChange('pressKey')}
            />
            <Select
                label="Type press"
                data={[
                    { value: 'PRESS', label: 'PRESS' },
                    { value: 'DOWN', label: 'DOWN' },
                    { value: 'UP', label: 'UP' },
                ]}
                style={{ marginTop: '8px' }}
                value={options.type}
                onChange={onSelectChange('type')}
            />
        </>
    )
}

export default PressKey;
