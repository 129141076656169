import React, {useContext, useEffect, useState} from "react";
import {ActionIcon, Button, Flex, Select, Text, TextInput} from "@mantine/core";
import {v4 as uuid} from "uuid";
import {IconCode} from "@tabler/icons";
import {DataFlowContext} from "../../../../core/context/DataFlowContext";

const GetData = (props) => {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options?.dataArr ?? [])
    const leftSection = (<ActionIcon radius="md"><IconCode size={16} /></ActionIcon>)
    const data = useContext(DataFlowContext)

    const newData = data.nodeValue.variables.filter(variable => variable.label !== "")

    const handleChangeValue = (id) => (e) => {
        const { name, value } = e.target
        let result = dataState.map((item) => {
            if (item.id === id) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setDataState(result)
    }

    const handleSelectValue = (value, id) => {
        let result = dataState.map((item) => {
            if (item.id === id) {
                return { ...item, "value": value }
            } else {
                return item
            }
        })
        setDataState(result)
    }

    const handleRemoveInput = (id) => {
        if (dataState.length === 1) return
        setDataState((pre) => pre.filter(item => item.id !== id))
    }

    const handleAddInput = () => {
        setDataState((pre) => [...pre, {
            id: uuid(),
            key: "",
            value: ""
        }])
    }

    const handleSetDataBaseModal = props.handleSetDataBaseModal;
    useEffect(() => {
        // const newData = dataState.filter(item => item.key !== '')
        const options = {
            dataArr: dataState
        }
        handleSetDataBaseModal(options)
    },[handleSetDataBaseModal, dataState])

    return (
        <>
            {
                dataState?.map((ele, index) => (
                    <Flex
                        key={index}
                        mt="xs"
                        direction={{ base: 'column', sm: 'row' }}
                        gap={{ base: 'sm', sm: 'lg' }}
                        justify={{ sm: 'center' }}
                    >
                        <TextInput
                            withAsterisk
                            placeholder="Key"
                            name="key"
                            value={ele.key}
                            onChange={handleChangeValue(ele.id)}
                        />
                        <Text lh="2.4">&#61;</Text>
                        <Select
                            data={newData}
                            placeholder="Select Variable"
                            nothingFound="No created variables"
                            searchable
                            value={ele.value}
                            onChange={(value) => handleSelectValue(value, ele.id)}
                            creatable
                            getCreateLabel={(query) => `+ Create ${query}`}
                            onCreate={(query) => {
                                const item = { id: uuid(), value: query, label: query, data: "", name: "" };
                                data.setNodeValue({
                                    variables: [...data.nodeValue.variables, item]
                                })
                                return item;
                            }}
                            icon={leftSection}
                        />
                        <Button.Group>
                            <Button variant="default" onClick={() => handleRemoveInput(ele.id)}>&#8722;</Button>
                            <Button variant="default" onClick={handleAddInput}>&#43;</Button>
                        </Button.Group>
                    </Flex>
                ))
            }
        </>
    )
}
export default GetData