import {useEffect, useRef, useState} from "react";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";
import { Button, Input } from "@mantine/core";
import {IconFileUpload, IconFolder} from "@tabler/icons";

function DownloadFile(props) {
    const folderRef = useRef(null)
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        url: '',
        filePath: '',
    })
    const handleSelector = (type, value) => {
        if (typeof value === 'string' || typeof value === 'number') {
            setDataState({...dataState, [type]: value});
        } else {
            setDataState({...dataState, [type]: value.target.value});
        }
    }
    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    const onSelectFolder = async (e) => {
        e.preventDefault()
        const path = await window.electron.ipcRenderer.invoke('getDictionary')
        setDataState(state => ({...state, filePath: path }))
    }
    return (
        <div style={{ marginBottom: 8 }}>
            <VariableSelectWrite
                placeholder="Enter URL"
                label="URL"
                dataState={dataState.url}
                handleData={(e) => {
                    handleSelector('url', e)
                }}
                handleSelect={(e) => {
                    handleSelector('url', e.newValue)
                }}
            />
            <div className="custom-wrapper">
                <div className="block-input">
                    <VariableSelectWrite
                        placeholder="Enter full path or upload file"
                        label="Path to the file"
                        dataState={dataState.filePath}
                        setDataState={handleSelector}
                        handleData={(e) => {
                            handleSelector('filePath', e)
                        }}
                        handleSelect={(e) => {
                            handleSelector('filePath', e.newValue)
                        }}
                    />
                </div>
                <Input
                    className="custom-file-input"
                    type="file"
                    onClick={onSelectFolder}
                    icon={<IconFileUpload size={16} />}
                    mt="0px"
                    styles={{ input: {
                        paddingRight: "0px",
                        '&::-webkit-file-upload-button': {
                            visibility: 'hidden',
                        },
                        cursor: 'pointer',
                    }}}
                />
            </div>
        </div>
    );
}

export default DownloadFile;
