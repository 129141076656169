import { useEffect, useState } from "react";
import { Button, Checkbox, FileButton, Input, SegmentedControl, Switch } from "@mantine/core";

import { convertToBase64 } from "./../../../../utils/fileToBase64";
import VariableSelectCreate from "../../../GlobalComponent/VariableSelectCreate";
import VariableNumberInput from "../../../GlobalComponent/VariableNumberInput";

const ImageSearchV2 = (props) => {
  const [dataState, setDataState] = useState(
    props?.nodeData?.data?.options ?? {
      image: "",
      outputVariableX: "",
      outputVariableY: "",
      timeout: "30000",
      filetype: "",
      algo: "tpl",
      threshold: 0.3,
      isFullScreen: false,
      rgb: true,
    }
  );

  const onChangeValue = (key, target) => (e) => {
    if (e?.type === "change") {
      setDataState((state) => ({
        ...state,
        [key]: target ? e.target[target] : e.target.value,
      }));
    } else if (e?.type === "click") {
      const value = e.target.getAttribute("value");
      const newValue = dataState[key] + "${" + value + "}";
      setDataState((state) => ({ ...state, [key]: newValue }));
    } else {
      setDataState((state) => ({ ...state, [key]: e }));
    }
  };

  const onChangeImage = async (file) => {
    const base64 = await convertToBase64(file);
    onChangeValue("image")(base64);
    onChangeValue("filetype")(file.type);
  };

  useEffect(() => {
    props.handleSetDataBaseModal(dataState)
  }, [props, dataState])

  return (
    <div>
      <div>
        <FileButton onChange={onChangeImage} accept="image/png,image/jpeg">
          {(props) => <Button {...props}>Choose image</Button>}
        </FileButton>
      </div>
      {dataState?.image && (
        <div style={{ marginTop: 12, marginBottom: 12, textAlign: "center" }}>
          <img
            alt=""
            src={dataState?.image}
            style={{ maxHeight: 120, width: "auto", maxWidth: "100%" }}
          />
        </div>
      )}
      <Switch
        label="Take a full screen"
        checked={dataState.isFullScreen}
        style={{ marginTop: 12 }}
        onChange={onChangeValue("isFullScreen", "checked")}
      />
      <div style={{ margin: '8px 0' }}>
        <label style={{ marginRight: 8 }}>Find Type</label>
        <SegmentedControl
          onChange={onChangeValue("algo")}
          value={dataState.algo}
          data={[
            { label: "Hide T", value: "tpl" },
            { label: "Hide K", value: "kaze" },
          ]}
        />
      </div>
      <Checkbox
        label="Enable Color Finder"
        checked={dataState.rgb}
        onChange={onChangeValue("rgb", "checked")}
      />
      <div style={{ margin: '8px 0', display: 'flex', alignItems: 'center' }}>
        <label style={{ marginRight: 8 }}>Sensibility</label>
        <input min={0} max={1} step={0.1} value={dataState.threshold} onChange={onChangeValue("threshold")} type="range"/>
        <span style={{marginLeft: 8}}>{dataState.threshold}</span>
      </div>
      <VariableSelectCreate
        label="X Coordinates Output Variable"
        placeholder="Select items"
        value={dataState.outputVariableX}
        handleChange={onChangeValue("outputVariableX")}
      />
      <VariableSelectCreate
        label="Y Coordinates Output Variable"
        placeholder="Select items"
        value={dataState.outputVariableY}
        handleChange={onChangeValue("outputVariableY")}
      />
      <VariableNumberInput
        label="Timeout waiting (milliseconds)"
        dataState={dataState.timeout}
        handleData={onChangeValue("timeout")}
        isMillisecond={true}
      />
    </div>
  );
}

export default ImageSearchV2
