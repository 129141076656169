import { ActionIcon, Button, Checkbox, Input, Radio, Text, Tooltip } from '@mantine/core';
import { useForm } from "@mantine/form";
import { cloneDeep } from "lodash";
import { useContext, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from "uuid";

import { IconFileUpload, IconInfoCircle, IconPlus, IconTrash } from '@tabler/icons';
import VariableSelectWrite from '../../../GlobalComponent/VariableSelectWrite';
import VariableSelectCreate from './../../../GlobalComponent/VariableSelectCreate';
import { AppSettingsContext } from '../../../../core/context/AppSettingsContext';

const DESCRIBE_KEY = {
    en: `
    If you select the checkbox "First row as key", the script will automatically recognize the first line of the selected range as the title of the data columns. 
    If you don't select this checkbox, the script will not consider the first line of the range you selected as the title of the data columns.
    `,
    vi: `
    Khi chọn checkbox ( firsr row as key) này thì script sẽ tự hiểu dòng đầu tiên của range bạn chọn là title của các cột dữ liệu.
    Khi không chọn checkbox ( firsr row as key) này thì script sẽ không lấy dòng đầu tiên của range bạn chọn là title của các cột dữ liệu nữa.
    `
}
const DESCRIBE_STORE = {
    vi: `
    index là stt của hàng dữ liệu ( trừ hàng title nếu bọn checkbox trên ) trong range bạn chọn bắt đầu từ 0 
    Product_id là title của cột, nhưng nếu không chọn checkbox trên thì là stt của cột VD : [0]["ten_cot_1"] or [0][0], và đây chỉ là một ô kp một 1 cột hay 1 hàng"
    `,
    en: `
    index is the number of the data row (except the title row if the checkboxes are above) in the range you choose starting from 0
    Product_id is the title of the column, but if the above checkbox is not selected, it is the status of the column. For example: [0]["name_cot_1"] or [0][0], and this is just a cell in 1 column or 1 row"
    `
}

const defaultState = {
    type: 'localFile',
    sheetId: '',
    filePath: '',
    range: '',
    sheetName: '',
    firstRowAsKey: false,
    storeVariable: [{ name: "", variableToSave: "", id: uuid() }],
}

function ReadGoogleSheet(props) {
    const defaultOptions = props?.nodeData?.data?.options ? {
        ...props?.nodeData?.data?.options,
        type: 'localFile'
    } : defaultState
    const fileRef = useRef(null)
    const {settings} = useContext(AppSettingsContext)
    const [dataState, setDataState] = useState(defaultOptions)

    const form = useForm({
        initialValues: {
            storeVariable: props?.nodeData?.data?.options?.storeVariable ?? defaultState.storeVariable,
        },
      });

    const onChangeValue = (key, target) => e => {
        if (e?.type === 'change') {
            setDataState((state) => ({...state, [key]: target ? e.target[target] : e.target.value}))
        } else if (e?.type === 'click') {
            const newValue = e.newValue || ""
            setDataState((state) => ({...state, [key]: newValue}))
        } else {
            setDataState((state) => ({...state, [key]: e}))
        }
    }
    const onChangeFormValue = (key, index) => (e) => {
        const newRowValue = cloneDeep(form.values.storeVariable[index]);
        if (e?.type === "change") {
          newRowValue[key] = e.target.value;
        } else if (e?.type === "click") {
          const newValue = e.newValue || ""
          newRowValue[key] = newValue;
        } else {
          newRowValue[key] = e;
        }
        if (key === "type") {
          newRowValue.value = "";
        }
        const newBody = cloneDeep(form.values.storeVariable);
        newBody[index] = newRowValue;
        form.setFieldValue("storeVariable", newBody);
        setDataState((state) => ({...state, storeVariable: newBody}))
      };

    useEffect(() => {
        props.handleSetDataBaseModal({...dataState, storeVariable: form.values.storeVariable })
    }, [props, dataState])

    const addNewRow = () => {
        const storeVariable = [...form.values.storeVariable];
        form.setFieldValue("storeVariable", [
          ...storeVariable,
          { name: "", value: "", type: "text", id: uuid() },
        ]);
    };
    const removeRow = (id) => {
        const newRows = form.values.storeVariable.filter(({ id: itemId }) => itemId !== id);
        form.setFieldValue("storeVariable", newRows);
        setDataState((state) => ({...state, storeVariable: newRows}))
    };
    const handleUpload = () => {
        setDataState({...dataState, filePath: fileRef.current.files[0].path});
    }
    const viewDocument = (e) => {
        e.preventDefault()
        window.api.mb_ipcRenderer.sendMsg("mb_open_link_web_browser", { url: 'https://docs.hidemium.io/automation-user-manual/data/spreadsheet'})
    }

    return (
        <>
            <div style={{ marginTop: 12 }}>
                <Radio.Group
                    value={dataState.type}
                    label="Choose file type"
                    onChange={onChangeValue("type")}
                    withAsterisk
                >
                    <Radio value="localFile" label="Local file" />
                    {/* <Radio value="googleSheet" label="Google Sheet" /> */}
                </Radio.Group>
                {
                    dataState.type === "googleSheet" ? (
                        <VariableSelectWrite
                            label="Google Spreadsheet ID"
                            placeholder="Enter text or choose variable"
                            dataState={dataState.sheetId}
                            handleData={onChangeValue('sheetId')}
                            handleSelect={onChangeValue('sheetId')}
                        />
                    ) : (
                        <div style={{ display: "flex", alignItems: "flex-end" }}>
                            <VariableSelectWrite
                                style={{ flex: 1 }}
                                placeholder="Enter full path or upload file"
                                label="Path to the file"
                                dataState={dataState.filePath}
                                setDataState={onChangeValue('filePath')}
                                handleData={onChangeValue('filePath')}
                                handleSelect={onChangeValue('filePath')}
                            />
                            <Input
                                className="custom-file-input"
                                type="file"
                                ref={fileRef}
                                onChange={handleUpload}
                                icon={<IconFileUpload size={16} />}
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                mt="0px"
                                styles={(theme) => ({
                                    input: {
                                        paddingRight: "0px",
                                        '&::-webkit-file-upload-button': {
                                            visibility: 'hidden',
                                        },
                                        cursor: 'pointer',
                                    },
                                })}
                            >
                            </Input>
                        </div>
                    )
                }
                <div style={{ display: "flex" }}>
                    <VariableSelectWrite
                        style={{ flex: 1 }}
                        label="Range"
                        placeholder="EX: A1:C5"
                        dataState={dataState.range}
                        handleData={onChangeValue('range')}
                        handleSelect={onChangeValue('range')}
                    />
                    <VariableSelectWrite
                        style={{ flex: 1, marginLeft: 12 }}
                        label="Sheet name (Optional)"
                        placeholder="Enter text or choose variable"
                        dataState={dataState.sheetName}
                        handleData={onChangeValue('sheetName')}
                        handleSelect={onChangeValue('sheetName')}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'end' }}>
                    <Checkbox
                        style={{ marginTop: 12, marginRight: 12 }}
                        checked={dataState.firstRowAsKey}
                        label="First row as keys"
                        onChange={onChangeValue('firstRowAsKey', 'checked')}
                    />
                    <Tooltip label={DESCRIBE_KEY[settings.language] ?? DESCRIBE_KEY.en} multiline w={400} withArrow transitionProps={{ duration: 700 }}>
                        <ActionIcon>
                            <IconInfoCircle/>
                        </ActionIcon> 
                    </Tooltip>
                </div>
                <div style={{ display: 'flex', alignItems: 'end' }}>
                    <label
                        style={{ marginBottom: 4, marginRight: 12 }}
                        className="mantine-InputWrapper-label mantine-Textarea-label mantine-ittua2"
                    >Storage</label>
                    <Tooltip label={DESCRIBE_STORE[settings.language] ?? DESCRIBE_STORE.en} multiline w={400} withArrow transitionProps={{ duration: 700 }}>
                        <ActionIcon>
                            <IconInfoCircle/>
                        </ActionIcon> 
                    </Tooltip>
                </div>
                {
                    form.values.storeVariable.map(({ name, variableToSave, id }, index) => (
                        <div key={id} style={{ display: "flex", alignItems: "end" }}>
                            <VariableSelectWrite
                                style={{ flex: 1 }}
                                label="Map key"
                                placeholder='EX: [${index}][product_id]'
                                dataState={name}
                                handleData={onChangeFormValue('name', index)}
                                handleSelect={onChangeFormValue('name', index)}
                            />
                            <VariableSelectCreate
                                style={{ flex: 1, marginLeft: 12 }}
                                label="Save to"
                                value={variableToSave}
                                handleChange={onChangeFormValue('variableToSave', index)}
                            />
                            <Button
                                style={{ width: "85px", marginLeft: "12px" }}
                                color="red"
                                onClick={() => removeRow(id)}
                            >
                                <IconTrash size={20} />
                            </Button>
                        </div>
                    ))
                }
                <div style={{ textAlign: "end" }}>
                    <Button
                        rightIcon={<IconPlus size={16} />}
                        mt="sm"
                        onClick={addNewRow}
                    >
                    Add
                    </Button>
                </div>
            </div>
        </>
    );
}

export default ReadGoogleSheet;
