import React, { useContext, useEffect, useRef, useState } from "react";
import { ActionIcon, Popover, ScrollArea, Select, TextInput } from "@mantine/core";
import { IconCode } from "@tabler/icons";
import { DataFlowContext } from "../../../../core/context/DataFlowContext";
import VariableSelectCreate from "../../../GlobalComponent/VariableSelectCreate";
import styles from "../../../GlobalStyles/styleInputSearch.module.css"

const SetVariable = (props) => {
    const [value, setValue] = useState(props?.nodeData?.data?.options?.value ?? "")
    const [variable, setVariable] = useState(props?.nodeData?.data?.options?.variable ?? "")
    const [operator, setOperator] = useState(props?.nodeData?.data?.options?.operator ?? "")
    const [openDropdown, setOpenDropdown] = useState(false)
    const data = useContext(DataFlowContext)
    const textareaRef = useRef(null)

    const handleDropdown = () => {
        setOpenDropdown(!openDropdown)
    }
    const handleSetValue = (e) => {
        const textarea = textareaRef.current;
        const startPos = textarea.selectionStart;
        const firstPart = value.slice(0, startPos);
        const secondPart = value.slice(startPos);
        const valueSelected = e.target.getAttribute('value')
        const newValue = firstPart + "${" + valueSelected + "}" + secondPart
        setTimeout(() => {
            textarea.focus();
            textarea.setSelectionRange(startPos + valueSelected.length + 4, startPos + valueSelected.length + 3);
        }, 0);
        setOpenDropdown(false)
        setValue(newValue)
    }

    const handleInputValue = (value) => {
        setValue(value)
    }

    const handleSelectOperator = (value) => {
        setOperator(value)
    }

    const handleSetVariable = (value) => {
        setVariable(value)
    }

    const handleSetDataBaseModal = props.handleSetDataBaseModal;
    useEffect(() => {
        const options = {
            value: value,
            operator: operator,
            variable: variable
        }
        handleSetDataBaseModal(options)
    }, [handleSetDataBaseModal, value, operator, variable])

    const rightSection = (<ActionIcon radius="md" onClick={handleDropdown} variant="filled" color="yellow"><IconCode size={16} /></ActionIcon>)

    return (
        <>
            <VariableSelectCreate
                placeholder="Type variable name"
                value={variable}
                label="Variable name"
                handleChange={(event) => handleSetVariable(event)}
            />
            <Select
                mt="xs"
                label="Operator"
                placeholder="Select operator"
                value={operator}
                onChange={handleSelectOperator}
                data={[
                    { value: "=", label: "=" },
                    { value: "+", label: "+" },
                    { value: "-", label: "-" },
                    { value: "*", label: "*" },
                    { value: "/", label: "/" },
                    { value: "concatenate", label: "Concatenate" },
                ]}
            />
            <Popover opened={openDropdown} width="target" position="bottom" shadow="md">
                <Popover.Target>
                    <TextInput
                        mt="xs"
                        ref={textareaRef}
                        placeholder="Enter value or select variable"
                        value={value}
                        label="Variable or value"
                        onChange={(event) => handleInputValue(event.currentTarget.value)}
                        rightSection={rightSection}
                    />
                </Popover.Target>
                <Popover.Dropdown>
                <div style={{ maxHeight: '220px', display: 'flex'}}>
                    <ScrollArea style={{ flex: '1'}}>
                    <div className={styles.select_itemsWrapper} style={{ flexDirection: 'column' }}>
                        {data.nodeValue.variables.map((ele, index) => {
                            return (
                                <li
                                    key={index}
                                    onClick={(e) => handleSetValue(e)}
                                    value={ele.value}
                                    className={styles.select_item}
                                >
                                    {ele.label}
                                </li>
                            )
                        })}
                    </div>
                    </ScrollArea>
                </div>
                </Popover.Dropdown>
            </Popover>
        </>
    )
}
export default SetVariable