import React, { useEffect, useState } from "react";
import VariableSelectCreate from "../../../GlobalComponent/VariableSelectCreate";
import {Flex, NumberInput, Select, TextInput} from "@mantine/core";

const GetUrl = (props) => {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        extractionType: "fullUrl",
        searchKeyValue: "",
        dataUrl: "",
    });

    const handleSelector = (type, value) => {
        if (typeof value === 'string') {
            setDataState({ ...dataState, [type]: value });
        } else if (typeof value === 'object' && value === null) {
            setDataState({ ...dataState, [type]: "" });
        } else {
            setDataState({ ...dataState, [type]: value.target.value });
        }
    }

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    return (
        <>

            <Flex
                mih={50}
                gap="md"
                justify="flex-start"
                align="flex-start"
                direction="row"
                wrap="wrap"
            >
                <Select
                    value={dataState.extractionType}
                    label="Content"
                    placeholder="Pick one"
                    style={{width:"150px"}}
                    data={[
                        { value: 'fullUrl', label: 'Full URL' },
                        { value: 'domain', label: 'Domain' },
                        { value: 'searchKey', label: 'Search key' },
                    ]}
                    onChange={(e) => {
                        handleSelector('extractionType', e)
                    }}
                />
                {dataState.extractionType === 'searchKey' &&
                    <TextInput
                        label=" "
                        placeholder="Extract the correct search key"
                        value={dataState.searchKeyValue}
                        onChange={(e) => {
                            handleSelector('searchKeyValue', e)
                        }}
                    />
                }
            </Flex>
            <VariableSelectCreate
                style={{marginTop:"10px"}}
                label="Output Variable"
                placeholder="Select items"
                value={dataState.dataUrl}
                handleChange={(e) => {
                    handleSelector('dataUrl', e)
                }}
            />
        </>
    )
}

export default GetUrl;