import { Box } from "@mantine/core";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";
import { useEffect, useState } from "react";
import VariableSelectCreate from "../../../GlobalComponent/VariableSelectCreate";

const DESCRIPTION = 'The login scenario used for 2-step verification is the same as the verification code generated by Google Authenticator, Microsoft Authenticator, and Authy'

function AuthenticatorCode(props) {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        secret: '',
        saveTo: '',
    })
    const onChangeValue = (key, target) => e => {
        if (e?.type === 'change') {
            setDataState((state) => ({...state, [key]: target ? e.target[target] : e.target.value}))
        } else if (e?.type === 'click') {
            const newValue = e.newValue
            setDataState((state) => ({...state, [key]: newValue}))
        } else {
            setDataState((state) => ({...state, [key]: e}))
        }
    }
    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    return (
        <div>
            <Box
                sx={(theme) => ({
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                    padding: theme.spacing.sm,
                    borderRadius: theme.radius.md,
                    fontSize: '0.85rem',
                })}
            >
                {DESCRIPTION}
            </Box>
            <VariableSelectWrite
                label="Secret"
                placeholder="Please enter your authenticator key"
                dataState={dataState.secret}
                handleData={onChangeValue('secret')}
                handleSelect={onChangeValue('secret')}
            />
            <VariableSelectCreate
                label="Save To"
                placeholder="Choose or create variable"
                value={dataState.saveTo}
                handleChange={onChangeValue('saveTo')}
            />
        </div>
    );
}

export default AuthenticatorCode;
