import React, {useEffect, useState } from "react";
import VariableNumberInput from "../../../GlobalComponent/VariableNumberInput";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";

const TypeText = (props) => {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        intervalTime: 100,
        typeText: "",
    });

    const handleSelector = (type, value) => {
        if (typeof value === 'string' || typeof value === 'number') {
            setDataState({ ...dataState, [type]: value });
        } else {
            setDataState({ ...dataState, [type]: value.target.value });
        }
    }

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    return (
        <>
            <VariableSelectWrite
                placeholder=""
                label="Enter Text"
                dataState={dataState.typeText}
                handleData={(e) => {
                    handleSelector('typeText', e)
                }}
                handleSelect={(e) => {
                    handleSelector('typeText', e.newValue)
                }}
            />
            <VariableNumberInput
                label="Enter interval time  (milliseconds)"
                dataState={dataState.intervalTime}
                handleData={(e) => {
                  
                    handleSelector('intervalTime',e)
                }}
                isMillisecond={true}
            />
        </>
    )
}

export default TypeText;