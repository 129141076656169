import { Box, Button, Flex, Grid, Select } from "@mantine/core";
import React, { useEffect, useState } from "react";
import VariableNumberInput from "../../../GlobalComponent/VariableNumberInput";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";
import { useForm } from "@mantine/form";
import { v4 as uuid } from "uuid";
import { IconPlus, IconTrash } from "@tabler/icons";
import { cloneDeep } from "lodash"

const defaultState = [
  {
    id: uuid(),
    valueElementSelect: ""
  }
]

function ElementExistsV2(props) {
  const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
    elementSelects: defaultState,
    fixedElement: 1,
    isVisible: 'true',
    timeout: 30000
  });

  const form = useForm({
    initialValues: {
      elementSelects: props?.nodeData?.data?.options?.elementSelects ?? defaultState
    }
  });

  const handleSelector = (type, value) => {
    if (typeof value === 'string' || typeof value === 'number') {
      setDataState({ ...dataState, [type]: value });
    } else {
      setDataState({ ...dataState, [type]: value.target.value });
    }
  }

  const removeRow = (id, index) => {
    const newRows = form.values.elementSelects.filter(({ id: itemId }) => itemId !== id);
    form.setFieldValue('elementSelects', newRows);
    setDataState((state) => ({ ...state, elementSelects: newRows }))
    props.setEdges((edges) => {
      const newEdges = [];
      for (const edge of edges) {
        if (edge.source === props?.nodeData.id) {
          const edgeOrder = Number(edge.sourceHandle.split('-')[2])
          if (index !== edgeOrder) {
            newEdges.push(edge)
          }
          if (edgeOrder > index) {
            edge.sourceHandle = `success-elementSelect-${edgeOrder - 1}-${props?.nodeData.id}`
          }
        } else {
          newEdges.push(edge)
        }
      }
      return newEdges
    })
  }

  const addNewRow = () => {
    const currentValue = [...form.values.elementSelects]
    form.setFieldValue('elementSelects', [...currentValue, {
      id: uuid(),
      valueElementSelect: ""
    }])
    setDataState((state) => ({
      ...state, elementSelects: [...currentValue, {
        id: uuid(),
        valueElementSelect: ""
      }]
    }))
  }

  const onChangeFormValue = (key, index) => (e) => {
    const formKey = 'elementSelects'
    const newRowValue = cloneDeep(form.values[formKey][index]);
    if (e?.type === 'change') {
      newRowValue[key] = e.target.value;
    } else if (e?.type === "click") {
      const newValue = e.newValue || ""
      newRowValue[key] = newValue;
    } else {
      newRowValue[key] = e;
    }
    if (key === "type") {
      newRowValue.value = "";
    }
    const newBody = cloneDeep(form.values[formKey]);
    newBody[index] = newRowValue;
    form.setFieldValue(formKey, newBody);
    setDataState((state) => ({ ...state, [formKey]: newBody }))
  }

  useEffect(() => {
    props.handleSetDataBaseModal({ ...dataState, elementSelects: form.values.elementSelects })
  }, [props, dataState])

  return (
    <>
      <Box component="form" mx="auto">
        <Flex align="center">
          <VariableNumberInput
            style={{ width: "200px", marginRight: "30px" }}
            label="Element order"
            dataState={dataState.fixedElement}
            handleData={(e) => {
              handleSelector('fixedElement', e)
            }}
          />
          <Select
            value={dataState.isVisible}
            label="Visible"
            placeholder="Pick one"
            style={{ width: "150px" }}
            data={[
              { value: 'false', label: 'False' },
              { value: 'true', label: 'True' },
            ]}
            onChange={(e) => {
              handleSelector('isVisible', e)
            }}
          />
        </Flex>
        <VariableNumberInput
          label="Timeout waiting (milliseconds)"
          dataState={dataState.timeout}
          handleData={(e) => {
            handleSelector('timeout', e)
          }}
          isMillisecond={true}
        />
        {form.values.elementSelects.map(({ id, valueElementSelect }, index) => (
          <Grid
            key={index}
            grow
            align="flex-end"
          >
            <Grid.Col span={10}>
              <VariableSelectWrite
                dataState={valueElementSelect}
                label="Select element"
                placeholder="Please enter the element, such as #email input"
                handleData={onChangeFormValue('valueElementSelect', index)}
                handleSelect={onChangeFormValue('valueElementSelect', index)}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              {form.values.elementSelects.length > 1 ? (
                <Button
                  color="red"
                  onClick={() => removeRow(id, index)}
                >
                  <IconTrash size={20} />
                </Button>
              ) : null}
            </Grid.Col>
          </Grid>
        ))}
        <Button
          rightIcon={<IconPlus size={16} />}
          mt="sm"
          onClick={addNewRow}
        >
          Add
        </Button>
      </Box>
    </>
  );
}

export default ElementExistsV2;