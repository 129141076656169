import {createContext, useRef, useState} from "react";

const AppSettingsContext = createContext();
function AppSettingsProvider({children}) {
    const [settings, setSettings] = useState({ language: 'en', autoVersion: 0, isCloud: true, updateId: '' });
    const browserRunTest = useRef(null)
    const userAgentConfig = useRef({})
    const userCharge = useRef({})
    const [nodeActive, setNodeActive] = useState(null)

    const value = {
        settings,
        setSettings,
        browserRunTest,
        userAgentConfig,
        userCharge,
        nodeActive,
        setNodeActive,
    }

    return (
        <AppSettingsContext.Provider value={value}>
            {children}
        </AppSettingsContext.Provider>
    )
}

export {AppSettingsContext, AppSettingsProvider}