import {Select} from "@mantine/core";
import {v4 as uuid} from "uuid";
import React, {useContext} from "react";
import {DataFlowContext} from "../../core/context/DataFlowContext";

const VariableSelectCreate = ({label, placeholder, value, handleChange, className, style, customCreate = false, order, customFunc}) => {
    const dataSelect = useContext(DataFlowContext)

    const newData = dataSelect.nodeValue.variables.filter(variable => variable.label !== "")
    return (
        <Select
            style={style}
            className={className}
            label={label}
            data={newData}
            placeholder={placeholder}
            nothingFound="Nothing found"
            searchable
            value={value}
            onChange={handleChange}
            order={order}
            creatable
            clearable
            getCreateLabel={(query) => `+ Create ${query}`}
            onCreate={(query) => {
                const item = { id: uuid(), value: query, label: query, data: "", name: "" };
                dataSelect.setNodeValue({
                    variables: [...dataSelect.nodeValue.variables, item]
                })
                if (customCreate) {
                    customFunc(order, query);
                }
                return item;
            }}
        />)
}

export default VariableSelectCreate;