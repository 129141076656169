import { useMantineTheme } from '@mantine/core';
import React from 'react'

const ImageSeawrchV2 = () => {
  const theme = useMantineTheme();
  const viewDocument = (e) => {
    if (window.api.mb_ipcRenderer) {
      e.preventDefault()
      window.api.mb_ipcRenderer.sendMsg("mb_open_link_web_browser", { url: 'https://education.hidemium.io/courses/automation-foundation-automation-co-ban/lessons/image-search-v2/'})
    }
  }

  return (
    <div>
      <a
            style={{ fontSize:13, color: theme.colorScheme === 'dark' ? theme.colors.gray[0] : 'blue' }}
            href='https://education.hidemium.io/courses/automation-foundation-automation-co-ban/lessons/image-search-v2/'
            onClick={viewDocument}
            target='_blank'
        >
            Click here to read instructions how to use node Image Search V2
        </a>
    </div>
  )
}

export default ImageSeawrchV2
