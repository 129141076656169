import {MultiSelect, Select} from "@mantine/core";
import {v4 as uuid} from "uuid";
import React, {useContext} from "react";
import {DataFlowContext} from "../../core/context/DataFlowContext";

const VariableSelect = ({label, placeholder, value, handleChange, className, style, multiple = false}) => {
    const dataSelect = useContext(DataFlowContext)
    const newData = dataSelect.nodeValue.variables.filter(variable => variable.label !== "")
    if (multiple === true) {
        return (<MultiSelect
            style={style}
            className={className}
            label={label}
            data={newData}
            placeholder={placeholder}
            nothingFound="Nothing found"
            clearable
            searchable
            value={value}
            onChange={handleChange}
            dropdownPosition="flip"
        />)

    } else {
        return (<Select
            style={style}
            className={className}
            label={label}
            data={newData}
            placeholder={placeholder}
            nothingFound="Nothing found"
            clearable
            searchable
            value={value}
            onChange={handleChange}
        />)
    }
}

export default VariableSelect;