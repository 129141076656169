import { ActionIcon, Popover, TextInput } from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { IconCode, IconX } from "@tabler/icons";
import { DataFlowContext } from "../../core/context/DataFlowContext";

const VariableNumberInput = ({ label, placeholder, dataState, handleData, isMillisecond = false, description, className, style }) => {
    const [openDropdown, setOpenDropdown] = useState(false)
    const [value, setValue] = useState(dataState)
    const dataSelect = useContext(DataFlowContext)
    const handleDropdown = () => {
        setOpenDropdown(!openDropdown)
    }
    useEffect(() => {
        setValue(dataState)
        setOpenDropdown(false)
    }, [dataState])

    const rightSection = (<ActionIcon onClick={handleDropdown} variant="filled" color="yellow" ><IconCode size={16} /></ActionIcon>)
    return (
        <>
            <Popover opened={openDropdown} width="target" position="bottom" shadow="md">
                <Popover.Target>
                    <TextInput
                        style={style}
                        className={className}
                        placeholder={placeholder}
                        value={value}
                        label={label}
                        description={description}
                        onChange={(e) => {
                            const acceptValue = ['', '-']
                            if (acceptValue.includes(e.target.value) || !isNaN(e.target.value)) {
                                handleData(e.target.value)
                            }
                        }}
                        rightSection={
                            typeof value === 'string' && value !== '' ? (
                                <ActionIcon onClick={() => {
                                    setOpenDropdown(!openDropdown)
                                    setValue('')
                                    handleData('')
                                }} variant="transparent" ><IconX size={16} />
                                </ActionIcon>
                            ) : rightSection
                        }
                    />
                </Popover.Target>
                <Popover.Dropdown style={{ maxHeight: '40vh', overflow: 'auto' }}>
                    {dataSelect.nodeValue.variables.map((ele, index) => {
                        return (
                            <li
                                key={index}
                                onClick={(e) => {
                                    const value = e.target.getAttribute('value')
                                    handleData("${" + value + "}")
                                }}
                                value={ele.value}
                            >
                                {ele.label}
                            </li>
                        )
                    })}
                </Popover.Dropdown>
            </Popover>
        </>

    )
}

export default VariableNumberInput;