import React, { memo, useContext } from 'react';
import './Comment.css'
import { Text, ActionIcon } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons';
import {DataFlowContext} from "../../../core/context/DataFlowContext";
import { AppSettingsContext } from '../../../core/context/AppSettingsContext';

const Comment = ({ id, data }) => {
    const dataFlowContext = useContext(DataFlowContext);
    const { nodeActive } = useContext(AppSettingsContext)
    const isActive = nodeActive?.id === id
    const handleRemoveNode = (e) => {
        e.stopPropagation();
        data.onRemoveNode(id);
    }

    const handleOpenNode = (e) => {
        e.stopPropagation();
        data.onUpdateNode(id)
    }

    return (
        <div className={`node-comment ${isActive && 'active'}`}>
             <div className='action-node'>
                <ActionIcon onClick={handleRemoveNode} color="red" size="sm" radius="xl">
                    <IconTrash size={14} />
                </ActionIcon>
                <ActionIcon onClick={handleOpenNode} color="blue" size="sm" radius="xl">
                    <IconEdit size={14} />
                </ActionIcon>
            </div>
            <div className='node-normal-body'>
                <Text ml="xs" color="black" className="text-wb ms-0">{dataFlowContext.comment.hasOwnProperty(id) && dataFlowContext.comment[id] !== "" ? dataFlowContext.comment[id]  : "Comment here"}</Text>
            </div>
        </div>
    );
}

export default memo(Comment);
