import React, { useEffect, useState, useRef, useContext } from "react";
import VariableSelect from "../../../GlobalComponent/VariableSelect";
import VariableSelectCreate from './../../../GlobalComponent/VariableSelectCreate';
import { Select, MultiSelect } from "@mantine/core";
import { DataFlowContext } from "../../../../core/context/DataFlowContext";

import CodeEditor from "../../../GlobalComponent/CodeEditor";

const placeholder = `// Your codes return value...
`

const EvalCode = (props) => {
    const { nodeValue, onCreateNewVariable } = useContext(DataFlowContext)
    const [dataState, setDataState] = useState({
        scriptCode: props?.nodeData?.data?.options.scriptCode ??"",
        outputVariable: props?.nodeData?.data?.options.outputVariable ??"",
        injectVariable: props?.nodeData?.data?.options.injectVariable ??[],
        outputType: props?.nodeData?.data?.options.outputType ?? 'SINGLE',
    });

    const textareaRef = useRef(null);
    const handleChange = (key, target) => e => {
        if (e?.type === 'change') {
            setDataState((state) => ({...state, [key]: target ? e.target[target] : e.target.value}))
        } else if (e?.type === 'click') {
            const textarea = textareaRef.current;
            const startPos = textarea.selectionStart;
            const value = e.target.getAttribute('value')
            const firstPart = dataState[key].slice(0, startPos);
            const secondPart = dataState[key].slice(startPos);
            const newValue = firstPart + "${" + value + "}" + secondPart
            setDataState((state) => ({...state, [key]: newValue}))
            setTimeout(() => {
                textarea.focus();
                textarea.setSelectionRange(startPos + value.length + 4, startPos + value.length + 3);
            }, 0);
        } else {
            setDataState((state) => ({...state, [key]: e}))
        }
    }

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])
    return (
        <>
            {/* <VariableTextarea
                style={{marginTop: 0}}
                label=""
                textareaRef={textareaRef}
                placeholder={placeholder}
                dataState={dataState.scriptCode}
                handleData={handleChange("scriptCode")}
                handleSelect={handleChange('scriptCode')}
                resize="vertical"
                maxRows={12}
            /> */}
            <CodeEditor
                value={dataState.scriptCode}
                onChange={handleChange("scriptCode")}
                onValueChange={handleChange("scriptCode")}
                footer={`})(${dataState.injectVariable?.join(', ')})`}
                placeholder={placeholder}
                header={
                    (dataState.outputType === 'SINGLE' && dataState.outputVariable) ? `${dataState.outputVariable} = await (async function (${dataState.injectVariable?.join(', ')}) {` :
                    dataState.outputType === 'MULTIPLE' ? `[ ${dataState.outputVariable} ] = await (async function (${dataState.injectVariable?.join(', ')}) {` :
                    dataState.outputType === 'AUTO' ? `const result = await (async function (${dataState.injectVariable?.join(', ')}) {` :
                    `await (async function (${dataState.injectVariable?.join(', ')}) {`
                }
            />
            <VariableSelect
                style={{marginTop: "20px"}}
                label="Inject variables"
                value={dataState.injectVariable}
                handleChange={handleChange('injectVariable')}
                multiple={true}
            />
            <label className="mantine-InputWrapper-label mantine-Textarea-label mantine-ittua2" style={{ marginTop: 16 }}>
                Output options
            </label>
            <Select
                label="Variables mapping type"
                value={dataState.outputType}
                data={[
                    { label: 'Auto', value: 'AUTO' },
                    { label: 'Single', value: 'SINGLE' },
                    { label: 'Multiple', value: 'MULTIPLE' },
                ]}
                onChange={(event) => {
                    handleChange('outputVariable')('')
                    handleChange('outputType')(event)
                }}
            />
            {
                dataState.outputType === 'SINGLE' ? (
                    <VariableSelectCreate
                        style={{marginTop: "8px"}}
                        label="Output Variable"
                        value={dataState.outputVariable}
                        handleChange={handleChange('outputVariable')}
                    />
                ) : dataState.outputType === 'MULTIPLE' ? (
                    <MultiSelect
                        multiple creatable searchable
                        style={{marginTop: "8px"}}
                        label="Output Variables"
                        value={dataState.outputVariable}
                        onChange={handleChange('outputVariable')}
                        data={nodeValue.variables?.map(i => ({ value: i.label, label: i.label })) || []}
                        getCreateLabel={(query) => `+ Create ${query}`}
                        onCreate={(query) => {
                            onCreateNewVariable(query)
                            handleChange('outputVariable')([...dataState.outputVariable, query])
                        }}
                        dropdownPosition="flip"
                    />
                ) : null
            }
        </>
    );
}

export default EvalCode;